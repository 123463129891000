const INTER = [
    {
        space_src: 'baidujs',
        space_type: 'inters',
        space_id: 'u6943914',
        space_js: '//sjgfzpgb.timesaurora.com/production/u/po/resource/xlh/openjs/x/ex.js//sfzpgbz.gztechsw.com/source/o/j/resource/irfb/openjs/rs/common/f.js'
    }];
const BANNER = [
    {
        space_src: 'baidujs',
        space_type: 'native',
        space_id: 'u6943915',
        space_js: '//sfzpgbz.gztechsw.com/common/pkj-sg/production/c/source/s-th.js'
    }
];
const NATIVE_1 = [
    {
        space_src: 'baidujs',
        space_type: 'native',
        space_id: 'u6943916',
        space_js: '//sfzpgbz.gztechsw.com/common/qlkth-d-tu/openjs/k.js'
    }
];
const NATIVE_2 = [
    {
        space_src: 'baidujs',
        space_type: 'native',
        space_id: 'u6943917',
        space_js: '//sfzpgbz.gztechsw.com/site/r/production/m/resource/lu_ieuvn.js'
    }
];
const NATIVE_3 = [
    {
        space_src: 'baidujs',
        space_type: 'native',
        space_id: 'u6943918',
        space_js: '//sfzpgbz.gztechsw.com/source/sn/production/mvjf/static/vw_p.js'
    }
];


/** 广告位配置 */
export const SPACE_MAP = {
    Home_inter: INTER,
    Home_banner: BANNER,
    Main_native: NATIVE_1,
    Classroom_native: NATIVE_2,
    Wikipedia_cat_native: NATIVE_1,
    Wikipedia_dog_native: NATIVE_2,
    Wikipedia_other_native: NATIVE_3,
    ConstellationList_banner: BANNER,
    ConstellationList_native: NATIVE_1,
    ArticleDetail_inter: INTER,
    ArticleDetail_banner: BANNER,
    ArticleDetail_native: NATIVE_1,
    PetDetail_inter: INTER,
    PetDetail_banner: BANNER,
    PetDetail_native: NATIVE_1,
    Mood_dialog: NATIVE_3,
};
