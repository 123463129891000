<script setup lang="ts">
import { ref } from 'vue';
import { Haa } from '@haa/fwk-h5/lib/index';
import bus from '@haa/fwk-h5/lib/bus';
const { strategy } = Haa.util().useStrategy();
/** 展示类型，-1：不展示，0：官方插屏，1：对话框插屏 */
const showType = ref<number>(-1);
const setShowType = () => {
    if (strategy.h5_ad_space_config.Home_dialog) {
        showType.value = 1;
    } else if (strategy.h5_ad_space_config.Home_inter) {
        showType.value = 0;
    }
}
if (strategy.status != 0) {
    setShowType();
} else {
    bus.on('strategy', setShowType);
}

const handleClose = () => {
    showType.value = -1;
}
</script>

<template>
    <div class="dialog-welcome-wrap" :style="{'width': showType == 1 ? '100%': '0', 'height': showType == 1 ? '100%' : '0'}">
        <wap-inter v-if="showType == 0" space-key="Home_inter"></wap-inter>
        <div v-if="showType == 1" class="dialog-welcome-container">
            <div class="dialog-info">
                <img class="title" src="@/assets/images/common/dialog_title.png" />
                <div class="desc">hi萌友们～宠物汇拥有丰富的宠物知识库，在这里你可以了解到各式各样的宠物信息，还可以帮您解决在养宠的时候遇到的各种问题，同时还拥有精美的宠物图片和视频让您可以在这里畅快的云吸宠，快进来体验吧～</div>
            </div>
            <div class="click-btn" @click="handleClose">知道了</div>
            <div class="ad-wrap">
                <wap-native space-key="Home_dialog"></wap-native>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.dialog-welcome-wrap {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;left: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    .dialog-welcome-container {
        width: 690px;
        .dialog-info {
            background-image: url('@/assets/images/common/dialog_bg.png');
            background-size: 100% 100%;
            background-color: #fff;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 30px 50px;
            .title {
                width: 300px;
                height: 128px;
                margin-bottom: 30px;
            }
            .desc {
                font-size: 24px;
                line-height: 40px;
                color: #504B5B;
            }
        }
        .click-btn {
            height: 82px;
            border-radius: 20px;
            line-height: 82px;
            background: linear-gradient(92.46deg, #713EDD 10.81%, #D99ACF 92.69%);
            text-align: center;
            font-size: 30px;
            color: #fff;
            margin: 20px 0;
            font-weight: 600;
        }
        .ad-wrap {
            background-color: #fff;
            border-radius: 20px;
            overflow: hidden;
        }
    }
}
</style>
